import React from 'react'
import IOW40_Red from '../../icons/Oils/IOW40-Red.png'
import IOW40_Yellow from '../../icons/Oils/IOW40-Yellow.png'
import IOW50_Blue from '../../icons/Oils/IOW50-5-Blue.png'
import IOW50_Orange from '../../icons/Oils/IOW50-5-Orange.png'
import ProductsCard from './ProductsCard'
import OutlinedBtn from '../buttons/OutlinedBtn'
import { useNavigate } from 'react-router-dom'
import { cn } from '../../utils'

const ProductsComp = () => {
const navigate = useNavigate()
  const Products = [
    {
      images: [IOW40_Red, IOW40_Yellow],
      title: "10w40 SYNTHETIC FULLY - SEMI",
    },
    {
      images: [IOW50_Blue, IOW50_Orange],
      title: "10w50 SYNTHETIC FULLY - SEMI",
    },

  ]

  return (
    <div className='flex flex-col items-center'>
      <p className='Title'>Products</p>
      <p className='SubTitle font-medium mt-[20px] text-Light_Black text-center'>Discover unmatched excellence with our premium engine oils. Crafted for superior performance and longevity, our products ensure a smooth and reliable ride. Elevate your engine's efficiency and trust in our commitment to quality.</p>

      <div className='grid grid-cols-2 max_md:grid-cols-1 gap-[20px] w-full mt-[30px]'>
        {Products.map((itm1, idx1) => (
          <div key={idx1} className={cn('w-full bg-white min-h-[287px] max_sm:min-h-fit rounded-[5px] border border-Light_Blue flex flex-col items-center p-[30px] max_md:p-[20px]')} >
          <div className={cn('flex gap-x-[40px] justify-center')}>
            {itm1.images?.map((itm, idx) =>
              <img className='aspect-auto max_sm:w-[72px] max_sm:h-[100px]' key={idx} src={itm} alt='img' />
            )}
          </div>
          <p className='uppercase text-NavText max_md:text-NavTextTab text-Primary font-HandelGothic mt-[30px] text-center'>{itm1.title}</p>
    
        </div>
          // <ProductsCard key={idx} images={itm.images} title={itm.title} />
        ))}
      </div>

      <div className='w-[170px] mt-[30px]'>
        <OutlinedBtn title={'View All'} onClick={()=>navigate('/products')} />
      </div>
    </div>
  )
}

export default ProductsComp