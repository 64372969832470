import React, { useState } from "react";
import BaseContainer from "../layout/BaseContainer";
import InnerContainer from "../layout/InnerContainer";
import ProductsCard from "../components/homepage/ProductsCard";

// import OneLTR from "../icons/Products/1 Ltr 55.png";
// import TwoLTR from "../icons/Products/2 Ltr 4.png";
// import FiveLTR from "../icons/Products/5 Ltr 2.png";
// import IOW40_Red from "../icons/Oils/IOW40-Red.png";
// import IOW40_Yellow from "../icons/Oils/IOW40-Yellow.png";
// import IOW50_Blue from "../icons/Oils/IOW50-5-Blue.png";
// import IOW50_Orange from "../icons/Oils/IOW50-5-Orange.png";

import { cn } from "../utils";
import { useNavigate } from "react-router-dom";
import { productList } from "../components/Data";
import Footer from "../layout/Footer";

const Products = () => {
  const [hoverIndex,setHoverIndex] = useState(null)
  
  const navigate = useNavigate()

  const handleViewAll = (idx) => {
    console.log(idx);
    navigate(`/products/${idx}`)
  };
  return (
    <>
   
    <BaseContainer>
      <InnerContainer>
        <div className="flex flex-col items-center my-[80px] max_md:pt-[40px] max_sm:pt-[0px] pb-[30px]">
          <p className="Title">Products</p>
          <p className="SubTitle font-Roborto font-medium mt-[20px] text-Light_Black text-center">
            Discover our curated range of high-quality engine oils for optimal
            performance and durability. From efficient synthetic blends to
            reliable conventional oils, we have the perfect fit for your
            vehicle. Experience enhanced fuel efficiency and superior engine
            protection. Choose quality, choose us.
          </p>
          <div className="grid grid-cols-2 max_xl:grid-cols-1 gap-x-[20px] gap-y-[50px] mt-[50px]">
            {productList.map((itm, idx) => (
              <ProductsCard
                key={idx+1}
                imgDivClass={"px-[40px]"}
                className={"pb-[10px] px-[10px]"}
                images={itm.images}
                title={itm.title1}
                hoverIndex={hoverIndex} setHoverIndex={setHoverIndex}
                index={idx}
              >
                <button
                  type="button"
                  className={cn(
                    "w-full mt-[20px] h-[40px] border border-Primary_10 rounded-[5px]  duration-200 flex justify-center items-center font-Roborto text-paraText max_md:text-paraTextTab font-medium",
                    {
                      'bg-Primary !text-white': hoverIndex === idx, // Apply red background if isBack is equal to idx
                      'bg-Primary_10 !text-Primary': hoverIndex !== idx, // Apply default background if isBack is not equal to index
                    }
                  )}
                  onClick={() => handleViewAll(idx+1)}
                >
                  VIEW ALL
                </button>
              </ProductsCard>
            ))}
          </div>
        </div>
      </InnerContainer>
    </BaseContainer>
    <div className='!max-w-[1920px] !w-full overflow-hidden m-auto'>

        <Footer />
      </div>
    </>
  );
};

export default Products;
