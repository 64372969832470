import React, { useEffect, useState } from 'react'
import { ReactComponent as Logo } from "../icons/Logo.svg"
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { cn } from '../utils'
import InnerContainer from './InnerContainer'
import { useWindowWidthObserver } from '../hooks/useWindowWidth'

const Navbar = () => {
  const navigate = useNavigate();
  const windowWidth = useWindowWidthObserver();
  const location = useLocation();
  const [isOpenMenu, setIsOpenMenu] = useState(true)

  useEffect(() => {
    setIsOpenMenu(false)
  }, [location])

  return (
    <InnerContainer>
      <div className='h-[78px] m-auto flex items-center ' >
        <div className='flex justify-between items-center w-full'>
          <button className='w-fit' onClick={() => navigate('/')}>
            <Logo width={windowWidth < 501 ? 206 : ''} />
          </button>

          {/* HAMBERGER */}
          <button onClick={() => setIsOpenMenu(!isOpenMenu)} className='hidden max_md:block'>
            {isOpenMenu ? <CloseIcon /> : <Hamberger />}
          </button>

          {/* NAVBAR WEB */}
          <div className='max_md:hidden flex justify-between w-full max-w-[500px] max_xl:max-w-[390px]' >
            <NavLink
              to={'/'}
              className={({ isActive }) => {
                return cn('text-Black font-medium !text-NavText max_md:!text-NavTextTab uppercase', {
                  'text-Primary ': isActive
                })
              }
              }
            >
              Home
            </NavLink>
            <NavLink
              to={'/products'}
              className={({ isActive }) => {
                return cn('text-Black font-medium !text-NavText max_md:!text-NavTextTab uppercase', {
                  'text-Primary ': isActive
                })
              }
              }
            >
              Product
            </NavLink>
            <NavLink
              to={'/gallery'}
              className={({ isActive }) => {
                return cn('text-Black font-medium !text-NavText max_md:!text-NavTextTab uppercase', {
                  'text-Primary ': isActive
                })
              }
              }
            >
              Gallery
            </NavLink>
            <NavLink
              to={'/contact-us'}
              className={({ isActive }) => {
                return cn('text-Black font-medium !text-NavText max_md:!text-NavTextTab uppercase', {
                  'text-Primary ': isActive
                })
              }
              }
            >
              Contact us
            </NavLink>
          </div>

          {/* MOBILE */}
          {isOpenMenu && (
            <div className='absolute w-full left-0 top-[78px] bg-white py-[15px] px-[60px] z-50'>
              <div className='flex flex-col justify-between gap-[30px] w-full max-w-[400px]'>
                <NavLink
                  to={'/'}
                  className={({ isActive }) => {
                    return cn('text-Black px-[30px] font-medium !text-NavText max_md:!text-NavTextTab uppercase', {
                      'text-Primary ': isActive
                    })
                  }}
                >
                  Home
                </NavLink>
                <NavLink
                  to={'/products'}
                  className={({ isActive }) => {
                    return cn('text-Black px-[30px] font-medium !text-NavText max_md:!text-NavTextTab uppercase', {
                      'text-Primary ': isActive
                    })
                  }}
                >
                  Product
                </NavLink>
                <NavLink
                  to={'/gallery'}
                  className={({ isActive }) => {
                    return cn('text-Black px-[30px] font-medium !text-NavText max_md:!text-NavTextTab uppercase', {
                      'text-Primary ': isActive
                    })
                  }}
                >
                  Gallery
                </NavLink>
                <NavLink
                  to={'/contact-us'}
                  className={({ isActive }) => {
                    return cn('text-Black px-[30px] font-medium !text-NavText max_md:!text-NavTextTab uppercase', {
                      'text-Primary ': isActive
                    })
                  }}
                >
                  Contact us
                </NavLink>
              </div>
            </div>
          )}

        </div>
      </div>
    </InnerContainer>
  )
}

export default Navbar


const Hamberger = () => <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 35 30" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M0 2.5C0 1.83696 0.263392 1.20107 0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0H32.5C33.163 0 33.7989 0.263392 34.2678 0.732233C34.7366 1.20107 35 1.83696 35 2.5C35 3.16304 34.7366 3.79893 34.2678 4.26777C33.7989 4.73661 33.163 5 32.5 5H2.5C1.83696 5 1.20107 4.73661 0.732233 4.26777C0.263392 3.79893 0 3.16304 0 2.5Z" fill="#4A55A2" />
  <path fillRule="evenodd" clipRule="evenodd" d="M0 15C0 14.337 0.263392 13.7011 0.732233 13.2322C1.20107 12.7634 1.83696 12.5 2.5 12.5H32.5C33.163 12.5 33.7989 12.7634 34.2678 13.2322C34.7366 13.7011 35 14.337 35 15C35 15.663 34.7366 16.2989 34.2678 16.7678C33.7989 17.2366 33.163 17.5 32.5 17.5H2.5C1.83696 17.5 1.20107 17.2366 0.732233 16.7678C0.263392 16.2989 0 15.663 0 15Z" fill="#4A55A2" />
  <path fillRule="evenodd" clipRule="evenodd" d="M15 27.5C15 26.837 15.2634 26.2011 15.7322 25.7322C16.2011 25.2634 16.837 25 17.5 25H32.5C33.163 25 33.7989 25.2634 34.2678 25.7322C34.7366 26.2011 35 26.837 35 27.5C35 28.163 34.7366 28.7989 34.2678 29.2678C33.7989 29.7366 33.163 30 32.5 30H17.5C16.837 30 16.2011 29.7366 15.7322 29.2678C15.2634 28.7989 15 28.163 15 27.5Z" fill="#4A55A2" />
</svg>

const CloseIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 35 30" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.76777 27.7678C4.29893 27.2989 4.03553 26.663 4.03553 26C4.03553 25.337 4.29893 24.7011 4.76777 24.2322L25.981 3.01903C26.4498 2.55019 27.0857 2.2868 27.7487 2.2868C28.4118 2.2868 29.0477 2.55019 29.5165 3.01903C29.9853 3.48787 30.2487 4.12375 30.2487 4.7868C30.2487 5.44984 29.9853 6.08572 29.5165 6.55456L8.3033 27.7678C7.83446 28.2366 7.19858 28.5 6.53553 28.5C5.87249 28.5 5.23661 28.2366 4.76777 27.7678Z" fill="#4A55A2" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.23223 2.76777C5.70107 2.29893 6.33696 2.03553 7 2.03553C7.66304 2.03553 8.29893 2.29893 8.76777 2.76777L29.981 23.981C30.4498 24.4498 30.7132 25.0857 30.7132 25.7487C30.7132 26.4118 30.4498 27.0477 29.981 27.5165C29.5121 27.9853 28.8762 28.2487 28.2132 28.2487C27.5502 28.2487 26.9143 27.9853 26.4454 27.5165L5.23223 6.3033C4.76339 5.83446 4.5 5.19857 4.5 4.53553C4.5 3.87249 4.76339 3.23661 5.23223 2.76777Z" fill="#4A55A2" />
</svg>