import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const BaseContainer = ({ children }) => {
  return (
    <div>
      <div className='max-w-[1920px] m-auto sticky top-0 left-0 bg-white' style={{
        boxShadow: '1px 1px 6px 0px #0000001A'
      }}>
        <div className='w-full max-w-[1440px] overflow-hidden m-auto'>
          <Navbar />
        </div>
        {/* Content */}
      </div>
      <div className='w-full max-w-[1440px] overflow-hidden m-auto'>
        {children}
      </div>
    </div>
  )
}

export default BaseContainer