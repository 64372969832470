import React from 'react'

const InnerContainer = ({ children }) => {
  return (
    <div className='mx-[50px] max_md:mx-[20px]'>
      <div className='max-w-[1040px] m-auto'>{children}</div>
    </div>
  )
}

export default InnerContainer