import React from 'react'
import Hero1New from "../../icons/Home/Hero1New.png"
import Hero2 from "../../icons/Home/Hero 2.png"
import Hero3 from "../../icons/Home/Hero 3.png"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const HeroSection = () => {
  const Images = [Hero3, Hero2, Hero1New]
  return (
    // <div>
    //   <img src={Hero1} alt="img" />
    // </div>
    <>

      <div className='max_md:hidden'>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
          loop={true}
        >
          {Images.map((itm, idx) => (
            <SwiperSlide key={idx} style={{ background: "transparent" }}>
              <img src={itm} alt="img" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className='hidden max_md:block '>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper custom-class"
          loop={true}
        >
          {Images.map((itm, idx) => (
            <SwiperSlide key={idx} style={{ background: "transparent" }}>
              <img src={itm} alt="img" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>

  )
}

export default HeroSection