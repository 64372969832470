import React from 'react'

const Card = ({ icon, title, desc }) => {
  return (
    <div className='card_class w-full h-[332px] max-w-[245px] max_sm:h-[290px] shadow-[1px_1px_6px_0px_rgba(0,0,0,0.1)] rounded-[5px] pt-[30px] p-[20px] flex flex-col items-center' style={{boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.1)'
    }}>
      <span className='min-w-[40px] w-[40px]'>{icon}</span>
      <p className='text-CardTitle max_md:text-CardTitleTab mt-[10px] text-Black font-Lato font-bold'>{title}</p>
      <p className='mt-[20px] text-paraText max_md:text-paraTextTab text-center font-medium text-Light_Black'>{desc}</p>
    </div>
  )
}

export default Card