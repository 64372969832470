import React from 'react'
import Card from './Card'
import { ReactComponent as MissionIcon } from '../../icons/Home/Mission.svg'
import { ReactComponent as VisionIcon } from '../../icons/Home/Vision.svg'
import { ReactComponent as InfraIcon } from '../../icons/Home/Infra.svg'
import { ReactComponent as CarrerIcon } from '../../icons/Home/Carrer.svg'


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { useWindowWidthObserver } from '../../hooks/useWindowWidth'
import { useMemo } from 'react'

const Specialization = () => {
  const Specities = [
    {
      icon: <MissionIcon />,
      title: "Mission",
      desc: 'Specialized engine oils for peak performance, extended lifespan, and environmental responsibility. Trust us for tailored solutions and sustainable innovation.'
    },
    {
      icon: <VisionIcon />,
      title: "Vision",
      desc: 'Pioneering specialized engine oils for optimal performance, durability, and eco-friendliness. Drive towards a greener, more efficient future with our tailored lubrication solutions.'
    },
    {
      icon: <InfraIcon />,
      title: "Infrastructure",
      desc: 'Infrastructure built for precision, efficiency, and specialization. We invest in cutting-edge facilities to craft top-tier, tailored engine oils, meeting industry needs and exceeding expectations.'
    },
    {
      icon: <CarrerIcon />,
      title: "Career",
      desc: 'Specialization is our career creed. We nurture expertise, promoting growth & innovation within the realm of engine lubrication. Join us to specialize & drive the future of this dynamic industry.'
    },
  ]


  const windowWidth = useWindowWidthObserver();

  const slidePerView = useMemo(() => {
    if (windowWidth < 768) {
      return 1;
    } else if (windowWidth < 900) {
      return 2;
    } else if (windowWidth < 1160) {
      return 3;
    } else {
      return 4;
    }
  }, [windowWidth]);


  return (
    <div className='flex flex-col items-center'>
      <p className='Title'>Specialization</p>
      <p className='SubTitle font-medium mt-[20px] text-Light_Black text-center'>"Engineered for Peak Performance: Our Specialized Lubricants Maximize Efficiency & Prolong Engine Life.</p>
      <p className='SubTitle font-medium text-Light_Black'>Trust the Experts in Engine Oil."</p>
      {/* <div className='flex justify-between flex-wrap gap-x-[20px] w-full mt-[30px]'>
        {Specities.map((itm, idx) => (
          <Card key={idx} icon={itm.icon} title={itm.title} desc={itm.desc} />
        ))}
      </div> */}

      <Swiper
        slidesPerView={slidePerView}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper mt-[30px] !py-[20px] myswiper_custom"
        // loop={true}
      >
        {Specities.map((itm, idx) => (
          <SwiperSlide key={idx} style={{ background: "transparent"}}>
            <Card key={idx} icon={itm.icon} title={itm.title} desc={itm.desc} />

          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Specialization