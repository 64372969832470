import React from 'react'
import { cn } from '../../utils'

const ProductsCard = ({ children = undefined, imgDivClass, images, title, className,hoverIndex,setHoverIndex,index }) => {
  return (
    <div style={{boxShadow:hoverIndex == index ? '1px 1px 6px rgba(0, 0, 0, 0.1)':''}} className={cn('w-full bg-white min-h-[287px] max_sm:min-h-fit rounded-[5px] border border-Light_Blue flex flex-col items-center hover:border-Primary p-[30px] max_md:p-[20px]', className)} onMouseEnter={()=>setHoverIndex(index)} onMouseLeave={()=>setHoverIndex(null)}>
      <div className={cn('flex gap-x-[40px] justify-center', imgDivClass)}>
        {images?.map((itm, idx) =>
          <img className='aspect-auto max_sm:w-[72px] max_sm:h-[100px]' key={idx} src={itm} alt='img' />
        )}
      </div>
      <p className='uppercase text-NavText max_md:text-NavTextTab text-Primary font-HandelGothic mt-[30px] text-center'>{title}</p>

      {children && children}
    </div>
  )
}

export default ProductsCard