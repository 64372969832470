import React from 'react'
import AboutUS from "../../icons/Home/AboutUs.png"

const AboutUs = () => {
  return (
    <div className='grid grid-cols-2 gap-[20px] max_md:grid-cols-1 place-items-center'>
      <p className='Title hidden max_md:block'>About Us</p>
      <img className='self-start' src={AboutUS} alt='img' />

      <div className='flex w-full flex-col max_md:text-center'>
        <p className='Title max_md:hidden'>About Us</p>
        <p className='SubTitle mt-[30px] text-Black'>Welcome to our engine oil company, where passion for performance fuels our every endeavor. We are not just a brand; we are the epitome of innovation, crafted to elevate your driving experience. Our legacy is built on a foundation of engineering expertise, pushing the boundaries of possibility in the automotive world.</p>
        <p className='SubTitle mt-[20px] text-Black'>At our core, we believe in empowering your vehicle with the best. Our team of dedicated professionals works tirelessly to formulate engine oils that don't just meet industry standards but redefine them. We understand that your vehicle is more than just a mode of transportation; it's an extension of your identity, and we strive to ensure it operates at its absolute best.</p>
        <p className='SubTitle mt-[20px] text-Black'>With a commitment to research, development, and sustainability, we strive to make a difference in the world of lubricants. Our oils are not just about enhancing performance; they're about preserving the environment and contributing to a sustainable future. Join us on this exhilarating journey, as we revolutionize the automotive industry, one engine at a time. Discover the power of optimal performance with our meticulously crafted engine oils – because your journey deserves nothing less than the extraordinary.</p>

      </div>
    </div>
  )
}

export default AboutUs
