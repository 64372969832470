import JAS01 from "../icons/Products/JAS01.png"
import JAS02 from "../icons/Products/JAS02.png"
import JAS03 from "../icons/Products/JAS03.png"
import Syn4T01 from "../icons/Products/4T10W-01.png"
import Syn4T02 from "../icons/Products/4T10W-02.png"
import Syn4T03 from "../icons/Products/4T10W-03.png"
import Syn10W01 from "../icons/Products/I0W-01.png"
import Syn10W02 from "../icons/Products/I0W-02.png"
import SynW5001 from "../icons/Products/I0W50-01.png"
import SynW5002 from "../icons/Products/I0W50-02.png"
import Force01 from "../icons/Products/Force-01.png"
import Force02 from "../icons/Products/Force-02.png"
import Force03 from "../icons/Products/Force-03.png"
import Extrem01 from "../icons/Products/15WExtrem-01.png"
import Extrem02 from "../icons/Products/15WExtrem-02.png"
import Extrem03 from "../icons/Products/15WExtrem-03.png"
import Extrem04 from "../icons/Products/15WExtrem-04.png"
import Grade01 from "../icons/Products/20W50-01.png"
import Grade02 from "../icons/Products/20W50-02.png"
import MultiGrade01 from "../icons/Products/20W40-01.png"
import MultiGrade02 from "../icons/Products/20W40-02.png"
import Premium5W4001 from "../icons/Products/5W40-01.png"
import Premium5W4002 from "../icons/Products/5W40-02.png"
import Premium5W3001 from "../icons/Products/5W30-01.png"
import Premium5W3002 from "../icons/Products/5W30-02.png"
import Plus2T01 from "../icons/Products/2T+01.png"
import Plus2T02 from "../icons/Products/2T+02.png"
import Gear01 from "../icons/Products/Gear01.png"
import Gear02 from "../icons/Products/Gear02.png"
import Gear03 from "../icons/Products/Gear03.png"
import Gear04 from "../icons/Products/Gear04.png"
import Hydro01 from "../icons/Products/Hydro01.png"
import Hydro02 from "../icons/Products/Hydro02.png"
import Hydro03 from "../icons/Products/Hydro03.png"
import Pump01 from "../icons/Products/Pump-01.png"
import Pump02 from "../icons/Products/Pump-02.png"
import UTT01 from "../icons/Products/UTO-01.png"
import UTT02 from "../icons/Products/UTO-02.png"


import JS_SL_01 from '../icons/InnerProduct/JS500 ml SL.png'
import JS_SL_02 from '../icons/InnerProduct/JS1 Ltr 68SL.png'
import JS_SL_03 from '../icons/InnerProduct/JS2 Ltr SL.png'
import JS_SL_04 from '../icons/InnerProduct/JS3 Ltr SL.png'
import JS_SL_05 from '../icons/InnerProduct/JS5 Ltr SL.png'
import JS_SM_01 from '../icons/InnerProduct/JS500 ml SM.png'
import JS_SM_02 from '../icons/InnerProduct/JS1 Ltr SM.png'
import JS_SM_03 from '../icons/InnerProduct/JS2 Ltr SM.png'
import JS_SN_01 from '../icons/InnerProduct/JS500 ml 3SN.png'
import JS_SN_02 from '../icons/InnerProduct/JS1 Ltr 70SN.png'
import JS_SN_03 from '../icons/InnerProduct/JS2 Ltr SN.png'
import JS_SN_04 from '../icons/InnerProduct/JS3 Ltr SN.png'
import JS_SN_05 from '../icons/InnerProduct/JS5 Ltr SN.png'
// SECOND
import Sy4T_Full_01 from '../icons/InnerProduct/4T_Full_1 Ltr .png'
import Sy4T_Full_02 from '../icons/InnerProduct/4T_Full_5 Ltr.png'
import Sy4T_Min_01 from '../icons/InnerProduct/4T_Min1 Ltr .png'
import Sy4T_Min_02 from '../icons/InnerProduct/4T_Min5 Ltr.png'
import Sy4T_Semi_01 from '../icons/InnerProduct/4T_SEMI_1 Ltr.png'
import Sy4T_Semi_02 from '../icons/InnerProduct/4T_SEMI_5 Ltr 28.png'
// THIRD
import W50_Full_1 from '../icons/InnerProduct/10W50_Full_1.png'
import W50_Full_2 from '../icons/InnerProduct/10W50_Full_5.png'
import W50_Semi_1 from '../icons/InnerProduct/10W50_Semi_1.png'
import W50_Semi_2 from '../icons/InnerProduct/10W50_Semi_5.png'
// FOURTH
import LW50_Full_1 from '../icons/InnerProduct/I1W50_Full_1 Ltr.png'
import LW50_Full_2 from '../icons/InnerProduct/I1W50_Full_2 Ltr.png'
import LW50_Full_3 from '../icons/InnerProduct/I1W50_Full_3 Ltr.png'
import LW50_Semi_1 from '../icons/InnerProduct/LW50_Semi_1.png'
import LW50_Semi_2 from '../icons/InnerProduct/LW50_Semi_2.png'
import LW50_Semi_3 from '../icons/InnerProduct/LW50_Semi_5.png'
import LW50_SM_1 from '../icons/InnerProduct/LW50_SM_1.png'
import LW50_SM_2 from '../icons/InnerProduct/LW50_SM_2.png'
import LW50_SM_3 from '../icons/InnerProduct/LW50_SM_3.png'
// FIVE
import LW40_CF_1 from '../icons/InnerProduct/LW40_CF_1.png'
import LW40_CF_2 from '../icons/InnerProduct/LW40_CF_2.png'
import LW40_CF_3 from '../icons/InnerProduct/LW40_CF_3.png'
import LW40_CF_5 from '../icons/InnerProduct/LW40_CF_5.png'
import LW40_CH_1 from '../icons/InnerProduct/LW40_CH_1.png'
import LW40_CH_2 from '../icons/InnerProduct/LW40_CH_2.png'
import LW40_CH_3 from '../icons/InnerProduct/LW40_CH_3.png'
import LW40_CH_5 from '../icons/InnerProduct/LW40_CH_5.png'
import LW40_CI_1 from '../icons/InnerProduct/LW40_CI_1.png'
import LW40_CI_2 from '../icons/InnerProduct/LW40_CI_2.png'
import LW40_CI_3 from '../icons/InnerProduct/LW40_CI_3.png'
import LW40_CI_5 from '../icons/InnerProduct/LW40_CI_5.png'
import LW40_CIP_1 from '../icons/InnerProduct/LW40_CIP_1.png'
import LW40_CIP_2 from '../icons/InnerProduct/LW40_CIP_2.png'
import LW40_CIP_3 from '../icons/InnerProduct/LW40_CIP_3.png'
import LW40_CIP_5 from '../icons/InnerProduct/LW40_CIP_5.png'
// SIX
import W20_CNG_1 from '../icons/InnerProduct2/20W_SM_1.png'
import W20_CNG_2 from '../icons/InnerProduct2/20W_SM_2.png'
import W20_CNG_3 from '../icons/InnerProduct2/20W_SM_3.png'
import W20_BIKE_1 from '../icons/InnerProduct2/20W_BIKE_1.png'
import W20_BIKE_2 from '../icons/InnerProduct2/20W_BIKE_2.png'
import W20_BIKE_3 from '../icons/InnerProduct2/20W_BIKE_3.png'
// SEVEN
import Multi_1 from '../icons/InnerProduct2/Multi_1.png'
import Multi_2 from '../icons/InnerProduct2/Multi_2.png'
import Multi_3 from '../icons/InnerProduct2/Multi_3.png'
import Multi_5 from '../icons/InnerProduct2/Multi_5.png'
import Multi_SL_1 from '../icons/InnerProduct2/Multi_1_SL.png'
import Multi_SL_2 from '../icons/InnerProduct2/Multi_2_SL.png'
import Multi_SL_3 from '../icons/InnerProduct2/Multi_3_SL.png'
import Multi_SL_5 from '../icons/InnerProduct2/Multi_5_SL.png'
// Eight
import Prem_CI_1 from '../icons/InnerProduct2/Prem_CI_1.png'
import Prem_CI_2 from '../icons/InnerProduct2/Prem_CI_2.png'
import Prem_CI_3 from '../icons/InnerProduct2/Prem_CI_3.png'
import Prem_CI_5 from '../icons/InnerProduct2/Prem_CI_5.png'
import Prem_SN_1 from '../icons/InnerProduct2/Prem_SN_1.png'
import Prem_SN_2 from '../icons/InnerProduct2/Prem_SN_2.png'
import Prem_SN_3 from '../icons/InnerProduct2/Prem_SN_3.png'
import Prem_SN_5 from '../icons/InnerProduct2/Prem_SN_5.png'
// NINE
import Ultra_CI_1 from '../icons/InnerProduct2/Ultra_CI_1.png'
import Ultra_CI_2 from '../icons/InnerProduct2/Ultra_CI_2.png'
import Ultra_CI_3 from '../icons/InnerProduct2/Ultra_CI_3.png'
import Ultra_CI_5 from '../icons/InnerProduct2/Ultra_CI_5.png'
import Ultra_SN_1 from '../icons/InnerProduct2/Ultra_SN_1.png'
import Ultra_SN_2 from '../icons/InnerProduct2/Ultra_SN_2.png'
import Ultra_SN_3 from '../icons/InnerProduct2/Ultra_SN_3.png'
import Ultra_SN_5 from '../icons/InnerProduct2/Ultra_SN_5.png'
// TEN
import T2_Plus_1 from '../icons/InnerProducts3/2T_Plus_500.png'
import T2_Plus_2 from '../icons/InnerProducts3/2T_Plus_800.png'
import T2_Plus_3 from '../icons/InnerProducts3/2T_Plus_1L.png'
// ELEVEN
import Hydro_32_1 from '../icons/InnerProducts3/Hydro_32_1 Ltr.png'
import Hydro_32_5 from '../icons/InnerProducts3/Hydro_32_5 Ltr.png'
import Hydro_46_1 from '../icons/InnerProducts3/Hydro_46_1Ltr.png'
import Hydro_46_5 from '../icons/InnerProducts3/Hydro_46_5Ltr.png'
import Hydro_68_1 from '../icons/InnerProducts3/Hydro_68_1Ltr.png'
import Hydro_68_5 from '../icons/InnerProducts3/Hydro_68_5Ltr.png'
// Twelve
import Pump_1 from '../icons/InnerProducts3/Pump_1 Ltr.png'
import Pump_2 from '../icons/InnerProducts3/Pump_2 Ltr.png'
import Pump_3 from '../icons/InnerProducts3/Pump_3 Ltr.png'
import Pump_5 from '../icons/InnerProducts3/Pump_5 Ltr.png'
// THIRTY
import UT_1_Ltr from '../icons/InnerProducts3/UT_1 Ltr.png'
import UT_2_Ltr from '../icons/InnerProducts3/UT_2 Ltr.png'
import UT_3_Ltr from '../icons/InnerProducts3/UT_3 Ltr.png'
import UT_5_Ltr from '../icons/InnerProducts3/UT_5 Ltr.png'




export const productList = [
  {
    images: [JAS01, JAS02, JAS03],
    title: ["Tricomax JASO MA2 4T 20W40 SL","Tricomax JASO MA2 4T 20W40 Sm","Tricomax JASO MA2 4T 20W40 Sn"],
    title1:"JASO MA2 4T 20W40 SL - SM - SN",
    id: 1,
    desc: ['• 4T Four Stroke Engine Oil Developed to meet the critical requirements of high performance four stroke engine manufactured by leading auto makers.', '• As per requirement of JASO Ma2 & APL SL recommended for Bajaj, Honda, Hero, etc. Change oil as per manufacturer’s recommendation.'],
    sizes: '500 ml, 800 ml, 900 ml, 1 Ltr, 2 Ltr, 2.5 Ltr, 3 Ltr, 3.5 Ltr, 4.5 Ltr, 5 Ltr,  7.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr',
    options: [{ id: 1, title: 'SL GRADE' }, { id: 2, title: 'SM GRADE' }, { id: 3, title: 'SN GRADE' }],
    innerPhotos: [{ id: 1, images: [JS_SL_01, JS_SL_02, JS_SL_03, JS_SL_04, JS_SL_05] }, { id: 2, images: [JS_SM_01, JS_SM_02, JS_SM_03, JAS02, JAS03] }, { id: 3, images: [JS_SN_01, JS_SN_02, JS_SN_03, JS_SN_04, JS_SN_05] }]
  },
  {
    images: [Syn4T01, Syn4T02, Syn4T03],
    title: ["Tricomax 4T 10W30 SYNTHETIC FULLY ","Tricomax 4T 10W30 SYNTHETIC MINERAL","Tricomax 4T 10W30 SYNTHETIC semi"],
    title1:"4T 10W30 SYNTHETIC SEMI - MINERAL - FULLY",
    id: 2,
    sizes: '900 ml, 1 Ltr, 4.5 Ltr, 5 Ltr',
    options: [{ id: 1, title: 'FULLY' }, { id: 2, title: 'MINERAL' }, { id: 3, title: 'SEMI' }],
    innerPhotos: [{ id: 1, images: [Sy4T_Full_01, Sy4T_Full_02] }, { id: 2, images: [Sy4T_Min_01, Sy4T_Min_02] }, { id: 3, images: [Sy4T_Semi_01, Sy4T_Semi_02] }],
    desc: ['• 10W 30 is the super premium quality diesel engine oil specifically designed to address the operational severity and engine durability issues of the modern emission compliant diesel engines', '• Protects engine from excessive wear','• Higher levels of engine cleanliness'],

  },
  {
    images: [Syn10W01, Syn10W02],
    title: ["Tricomax 10w40 SYNTHETIC FULLY","Tricomax 10w40 SYNTHETIC SEMI"],
    title1:"10w40 SYNTHETIC FULLY - SEMI",
    id: 3,
    sizes: '900 ml, 1 Ltr, 4.5 Ltr, 5 Ltr',
    options: [{ id: 1, title: 'FULLY' }, { id: 2, title: 'SEMI' }],
    innerPhotos: [{ id: 1, images: [W50_Full_1, W50_Full_2] }, { id: 2, images: [W50_Semi_1, W50_Semi_2] },],
    desc: ['• 10W-40 grade is recommended for new generation geared 4- stroke bikes requiring a 10W-40 grade engine oil.', '• Longer oil life & Lower oil consumption - Excellent control on engine deposits','• Superior protection to gears - Longer engine life'],
  },
  {
    images: [SynW5001, SynW5002],
    title: ["Tricomax 10w50 FULLY SYNTHETIC","Tricomax 10w50 SEMI SYNTHETIC"],
    title1:"10w50 SYNTHETIC FULLY - SEMI",
    id: 4,
    sizes: '900 ml, 1 Ltr, 4.5 Ltr, 5 Ltr',
    options: [{ id: 1, title: 'FULLY' }, { id: 2, title: 'SEMI' }],
    innerPhotos: [{ id: 1, images: [W50_Full_1, W50_Full_2] }, { id: 2, images: [W50_Semi_1, W50_Semi_2] }],
    desc: ['• 10W-50 grade is recommended for new generation geared 4- stroke bikes requiring a 10W-50 grade engine oil.', '• Superior protection to gears & Longer engine life','• Longer oil life & Lower oil consumption'],
  },
  {
    images: [Force01, Force02, Force03],
    title: ["Tricomax 15W50 FULLY SYNTHETIC","Tricomax 15W50 SEMi SYNTHETIC","Tricomax FORCE 15W50 SM GRADE"],
    title1:"10w50 SYNTHETIC FULLY - SEMI",
    id: 5,
    sizes: '900 ml, 1 Ltr, 2 Ltr, 2.5 Ltr, 3 Ltr, 3.5 Ltr',
    options: [{ id: 1, title: 'FULLY' }, { id: 2, title: 'SEMI' }, { id: 3, title: 'SM' }],
    innerPhotos: [{ id: 1, images: [LW50_Full_1, LW50_Full_2, LW50_Full_3] }, { id: 2, images: [LW50_Semi_1, LW50_Semi_2, LW50_Semi_3] }, { id: 3, images: [LW50_SM_1,  LW50_SM_3,LW50_SM_2] }],
    desc:['• 15W50 oil is ultimate performer specially made for new generation turbo charged engines.', '• Intelligent molecules cling to metal surfaces giving extra protection against engine wear.','• Provides excellent soot handling performance thus minimising oil thickening.']

  },
  {
    images: [Extrem01, Extrem02, Extrem03, Extrem04],
    title: ["Tricomax 15w40 CF 4 EXTREME","Tricomax 15w40 CH 4 EXTREME","Tricomax 15w40 CI 4 EXTREME","Tricomax 15w40 CI 4+ EXTREME"],
    title1:"FORCE 15W50 SM - SEMI - FULLY ",
    id: 6,
    sizes: '900 ml, 1 Ltr, 2 Ltr, 2.5 Ltr, 3 Ltr, 3.5 Ltr, 4.5 Ltr, 5 Ltr',
    options: [{ id: 1, title: 'CF 4' }, { id: 2, title: 'CH 4' }, { id: 3, title: 'CI 4' }, { id: 4, title: 'CI 4+' }],
    innerPhotos: [{ id: 1, images: [LW40_CF_1, LW40_CF_2, LW40_CF_3, LW40_CF_5] }, { id: 2, images: [LW40_CH_1, LW40_CH_2, LW40_CH_3, LW40_CH_5] }, { id: 3, images: [LW40_CI_1, LW40_CI_2, LW40_CI_3, LW40_CI_5] }, { id: 4, images: [LW40_CIP_1, LW40_CIP_2, LW40_CIP_3, LW40_CIP_5] }],
    desc:['• 15W-40 Grade is recommended for new generation for new generation diesel engines of heavy commercial, Light commercial vehicles etc.','• Protects engine from excessive wear - Longer life for cylinder liner, piston ring','• Higher levels of engine cleanliness - Helps to reduce pollution']
  },
  // {
  //   images: [Grade01, Grade02],
  //   // title: ["Tricomax 20w50 sm grade cng","Tricomax 20w50 sm grade BIKE"],
  //   title1:"15w40 EXTREME CF 4 - CH 4 - CI 4 - CI 4+",
  //   id: 7,
  //   sizes: '900 ml, 1 Ltr, 2 Ltr, 2.5 Ltr, 3 Ltr, 3.5 Ltr, 4.5 Ltr, 5 Ltr',
  //   options: [{ id: 1, title: 'CNG' }, { id: 2, title: 'BIKE' }],
  //   // innerPhotos: [{ id: 1, images: [W20_CNG_1, W20_CNG_2, W20_CNG_3] }, { id: 2, images: [W20_BIKE_1, W20_BIKE_2, W20_BIKE_3] }]

  // },
  {
    images: [Grade01, Grade02],
    // title: ["Tricomax 20W40 MULTI GRADE CH 4","Tricomax 20W40 MULTI GRADE sl/cF 4"],
    title: ["Tricomax 20w50 sm grade cng","Tricomax 20w50 sm grade BIKE"],
    title1:"20w50 sm grade cng - BIKE",
    id: 7,
    sizes: '900 ml, 1 Ltr, 2 Ltr, 2.5 Ltr, 3 Ltr, 3.5 Ltr, 4.5 Ltr, 5 Ltr',
    // options: [{ id: 1, title: 'CH 4' }, { id: 2, title: 'SL/CF 4' }],
    options: [{ id: 1, title: 'CNG' }, { id: 2, title: 'BIKE' }],
    innerPhotos: [{ id: 1, images: [W20_CNG_1, W20_CNG_2, W20_CNG_3] }, { id: 2, images: [W20_BIKE_1, W20_BIKE_2, W20_BIKE_3] }],
    // innerPhotos: [{ id: 1, images: [Multi_1, Multi_2, Multi_3, Multi_5] }, { id: 2, images: [Multi_SL_1, Multi_SL_2, Multi_SL_3, Multi_SL_5] }]
    desc : ['• 20W50 SM Grade is a Premium Quality engine oil developed for 4-stroke autos and 3-wheelers running on CNG/LPG. The product is made from highly paraffinic base stocks and fortified with select additives to deliver satisfactory performance benefits in Autos and 3-wheelers running on CNG/LPG.']
  },
  {
    images: [MultiGrade01, MultiGrade02],
    title: ["Tricomax 20W40 MULTI GRADE CH 4","Tricomax 20W40 MULTI GRADE sl/cF 4"],
    // title: ["Tricomax 5W40 PREMIUM CI 4","Tricomax 5W40 PREMIUM SN/CF"],
    title1:"20W40 MULTI GRADE CH 4 - sl/cF 4",
    id: 8,
    sizes: '900 ml, 1 Ltr, 2 Ltr, 2.5 Ltr, 3 Ltr, 3.5 Ltr, 4.5 Ltr, 5 Ltr',
    options: [{ id: 1, title: 'CI 4' }, { id: 2, title: 'SL/CF 4' }],
    innerPhotos: [{ id: 1, images: [Multi_1, Multi_2, Multi_3, Multi_5] }, { id: 2, images: [Multi_SL_1, Multi_SL_2, Multi_SL_3, Multi_SL_5] }],
    desc: ['• 20W40 is a common motor oil used in moderate-to-large diesel and gasoline engines, high-performance motorcycle bikes, and aviation vehicles. Its resistance to thinning at high heat also makes it popular as a racing oil and high temperature driving.','•  Extra Protection to Engine, Clutch & Gears','• Outstanding Performance in all Weathers']

    // innerPhotos: [{ id: 1, images: [Multi_1, Multi_2, Multi_3, Multi_5] }, { id: 2, images: [Multi_SL_1, Multi_SL_2, Multi_SL_3, Multi_SL_5] }]
  },
  {
    images: [Premium5W4001, Premium5W4002],
    title: ["Tricomax 5W40 ULTRA CI 4","Tricomax 5W40 ULTRA sn/cF"],
    title1:"5W40 PREMIUM CI 4 - SN/CF",
    id: 9,
    sizes: '900 ml, 1 Ltr, 2 Ltr, 2.5 Ltr, 3 Ltr, 3.5 Ltr, 4.5 Ltr, 5 Ltr',
    options: [{ id: 1, title: 'CI 4' }, { id: 2, title: 'SN/CF 4' }],
    innerPhotos: [{ id: 1, images: [Ultra_CI_1, Ultra_CI_2, Ultra_CI_3, Ultra_CI_5] }, { id: 2, images: [Ultra_SN_1, Ultra_SN_2, Ultra_SN_3, Ultra_SN_5] }],
    desc : ['• 5W40 Premium CI 4 Fully Synthetic oil is Specially designed to provide excellent oxidative stability & heat resistive performance.','• Excellent oxidative stability & heat resistance for smoother drive.']
  },
  {
    images: [Premium5W3001, Premium5W3002],
    title: ["Tricomax 5W30 ULTRA CI 4","Tricomax 5W30 ULTRA sn/cF"],
    title1:"5W30 PREMIUM CI 4 - SN/CF",
    id: 10,
    sizes: '900 ml, 1 Ltr, 2 Ltr, 2.5 Ltr, 3 Ltr, 3.5 Ltr, 4.5 Ltr, 5 Ltr',
    options: [{ id: 1, title: 'CI 4' }, { id: 2, title: 'SN/CF 4' }],
    innerPhotos: [{ id: 1, images: [Ultra_CI_1, Ultra_CI_2, Ultra_CI_3, Ultra_CI_5] }, { id: 2, images: [Ultra_SN_1, Ultra_SN_2, Ultra_SN_3, Ultra_SN_5] }],
    desc : ['• 5W40 Premium CI 4 Fully Synthetic oil is Specially designed to provide excellent oxidative stability & heat resistive performance.','• Excellent oxidative stability & heat resistance for smoother drive.']
  },
  {
    images: [Plus2T01, Plus2T02],
    title: ["Tricomax 2T PLUS OIL"],
    title1:"2T PLUS OIL",
    id: 11,
    sizes: '500 ml, 800 ml, 900 ml, 1 Ltr',
    options: [{ id: 1, title: '2T plus oil' }],
    innerPhotos: [{ id: 1, images: [T2_Plus_1, T2_Plus_2, T2_Plus_3] }],
    desc: ['• 2T Engine Oil is low smoke semi-synthetic two stroke engine oil developed to meet the critical requirements of 2 stroke engines manufactured by all leading auto makers.','• It is blended from selected virgin base stocks.','• It is specifically formulated to give additional advantage of lower consumption of fuel.']

  },
  {
    images: [Gear01, Gear02, Gear03, Gear04],
    title: ["Tricomax Gear oil 85w 140","Tricomax Gear oil ep 90","Tricomax Gear oil ep 140","Tricomax Gear oil 80w 90"],
    title1:"Gear oil 85w 140 - ep 90 - ep 140 - 80w 90",
    id: 12,
    sizes: '900 ml, 1 Ltr, 4.5 Ltr, 5 Ltr',
    options: [{ id: 1, title: '85W 140' }, { id: 2, title: 'EP 90' }, { id: 3, title: 'EP 140' }, { id: 4, title: '80W 90' }],
    innerPhotos: [{ id: 1, images: [Prem_CI_1, Prem_CI_2, Prem_CI_3, Prem_CI_5] }, { id: 2, images: [Prem_SN_1, Prem_SN_2, Prem_SN_3, Prem_SN_5] }],
    desc : ['•  special multi-grade heavy duty hypoid gear oil possessing high load carrying capacity for sustained high speed and high torque service. It is ideal for vehicles operating in areas where ambient temperatures vary widely.','• Effective rust and corrosion protection reduces wear and extends component life.','• Excellent seal compatibility helps minimise leakages and reduce chances of contamination.']

  },
  {
    images: [Hydro01, Hydro02, Hydro03],
    title: ["Tricomax HYDRAULIC 32","Tricomax HYDRAULIC 46","Tricomax HYDRAULIC 68"],
    title1:"HYDRAULIC 32 - 46 - 68",
    id: 13,
    sizes: '900 ml, 1 Ltr, 4.5 Ltr, 5 Ltr',
    options: [{ id: 1, title: '32' }, { id: 2, title: '46' }, { id: 3, title: '68' }],
    innerPhotos: [{ id: 1, images: [Hydro_32_1, Hydro_32_5] }, { id: 2, images: [Hydro_46_1, Hydro_46_5, Hydro_68_1, Hydro_68_5] }],
    desc : ['• Viscosity – protects the oil from damage that could cause cavitation','• Viscosity Index – measure the viscosity from one temperature to another','• Wear Inhibitors – helps extend the equipment’s life by reducing wear']

  },
  {
    images: [Pump01, Pump02],
    title: ["Tricomax PUMP SET OIL"],
    title1:"PUMP SET OIL",
    id: 14,
    sizes: '900 ml, 1 Ltr, 2 Ltr, 2.5 Ltr, 3 Ltr, 3.5 Ltr, 4.5 Ltr, 5 Ltr',
    options: [{ id: 1, title: 'PUMP SET OIL' }],
    innerPhotos: [{ id: 1, images: [Pump_1, Pump_2, Pump_3,Pump_5] }],
    desc: ['• Tricomax Pump set oils are blended from highly refined base stocks and a combination of anti-oxidant, anti-wear and anti-foam additives to meet the lubrication requirement of agriculture pump sets.']

  },
  {
    images: [UTT01, UTT02],
    title: ["Tricomax U.T.T.O"],
    title1:'U.T.T.O',
    id: 15,
    sizes: '900 ml, 1 Ltr, 2 Ltr, 2.5 Ltr, 3 Ltr, 3.5 Ltr, 4.5 Ltr, 5 Ltr',
    options: [{ id: 1, title: 'U.T.T.O' }],
    innerPhotos: [{ id: 1, images: [UT_1_Ltr, UT_2_Ltr, UT_3_Ltr,UT_5_Ltr] }],
    desc : ['• Universal Tractor Transmission Oil  (UTTO) is an SAE 10W30 agricultural oil. UTTO oils are generally formulated to meet the requirements of off-highway moving equipment.','• Extra Protection to Engine, Clutch & Gears','• Outstanding Performance in all Weathers']

  },
];