import React from 'react'
import { cn } from '../utils'

const Tabs = ({ tabs = [], selectedTab = 0, onChange = undefined ,setSelectedTab}) => {
  return (
    <div className='flex border border-Primary rounded-[5px] overflow-hidden bg-Primary_10'>
      {tabs.map((itm, idx) => (
        <div
          key={idx}
          onClick={() => {
            // onChange &&
            //   onChange(idx)
            setSelectedTab(idx)
          }}
          className={cn('hover:bg-Primary hover:text-white cursor-pointer w-[182px] max_md:w-[150px] max_sm:w-[80px] h-[50px] max_sm:h-[30px] flex justify-center text-paraText max_sm:text-paraTextTab max_md:text-paraTextTab font-medium items-center', {
            '!bg-Primary text-white': selectedTab === idx
          })} >
          {itm.title}
        </div>
      ))}
    </div>
  )
}

export default Tabs