import React, { useState } from 'react'
import ProductDesc from '../components/product/ProductDesc'
import InnerContainer from '../layout/InnerContainer'
import BaseContainer from '../layout/BaseContainer'
import OneLTR from "../icons/Products/Gear01.png"
import TwoLTR from "../icons/Products/Gear02.png"
import FiveLTR from "../icons/Products/Gear03.png"
import IOW40_Red from '../icons/Oils/IOW40-Red.png'
import IOW40_Yellow from '../icons/Oils/IOW40-Yellow.png'
import OutlinedBtn from '../components/buttons/OutlinedBtn'
import { useNavigate, useParams } from 'react-router-dom'
import { productList } from '../components/Data'
import Footer from '../layout/Footer'


const ProductDescription = () => {
  const navigate = useNavigate()  
  const {id} = useParams();
  const [selectedTab,setSelectedTab] = useState(0)

  const findProduct = productList.find(product => product.id == id);
  const [selectedProducts,setSelectedProducts]= useState(findProduct)
console.log("selectedProducts.desc",selectedProducts.desc);
  return (
    <div>
      <BaseContainer>
        <InnerContainer>
          <div className='flex flex-col gap-[100px] mt-20'>

            <ProductDesc
              title={selectedProducts?.title[selectedTab]}
              desc={selectedProducts?.desc}
              // desc1={'• As per requirement of JASO Ma2 & APL SL recommended for Bajaj, Honda, Hero, etc. Change oil as per manufacturer’s recommendation.'}
              images={selectedProducts?.innerPhotos[selectedTab]}
              selectedTab={selectedTab}
              tabs={selectedProducts?.options}
              imgDivClass={''}
              setSelectedTab={setSelectedTab}
              sizes={selectedProducts?.sizes}
            />
            {/* <ProductDesc
              title={'Tricomax JASO MA2 4T 20W40 SL'}
              desc={'• 4T Four Stroke Engine Oil Developed to meet the critical requirements of high performance four stroke engine manufactured by leading auto makers.'}
              desc1={'• As per requirement of JASO Ma2 & APL SL recommended for Bajaj, Honda, Hero, etc. Change oil as per manufacturer’s recommendation.'}
              images={[
                OneLTR, TwoLTR, FiveLTR, IOW40_Red, IOW40_Yellow
              ]}
              selectedTab={1}
              tabs={[{ id: 1, title: 'SL GRADE' }, { id: 2, title: 'SL GRADE' }, { id: 3, title: 'SL GRADE' }]}
              imgDivClass={''}
              sizes={'500 ml, 800 ml, 900 ml, 1 Ltr, 2 Ltr, 2.5 Ltr, 3 Ltr, 3.5 Ltr, 4.5 Ltr, 5 Ltr,  7.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr'}
            /> */}
            {/* <ProductDesc
              title={'Tricomax JASO MA2 4T 20W40 SL'}
              desc={'• 4T Four Stroke Engine Oil Developed to meet the critical requirements of high performance four stroke engine manufactured by leading auto makers.'}
              desc1={'• As per requirement of JASO Ma2 & APL SL recommended for Bajaj, Honda, Hero, etc. Change oil as per manufacturer’s recommendation.'}
              images={[
                OneLTR, TwoLTR, FiveLTR, IOW40_Red, IOW40_Yellow
              ]}
              selectedTab={2}
              tabs={[{ id: 1, title: 'SL GRADE' }, { id: 2, title: 'SL GRADE' }, { id: 3, title: 'SL GRADE' }]}

              imgDivClass={''}
              sizes={'500 ml, 800 ml, 900 ml, 1 Ltr, 2 Ltr, 2.5 Ltr, 3 Ltr, 3.5 Ltr, 4.5 Ltr, 5 Ltr,  7.5 Ltr, 10 Ltr, 20 Ltr, 55 Ltr, 210 Ltr'}
            /> */}
          </div>

          <div className='w-full flex justify-center'>
            <div className='mt-[50px] w-[252px] flex '>
              <OutlinedBtn title='BACK TO PRODUCT' onClick={() => navigate('/products')} />
            </div>
          </div>
        </InnerContainer>
      </BaseContainer>
      <div className='!max-w-[1920px] !w-full overflow-hidden m-auto'>

        <Footer />
      </div>
    </div>
  )
}

export default ProductDescription