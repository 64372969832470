import React from 'react'
import HeroSection from '../components/homepage/HeroSection'
import InnerContainer from '../layout/InnerContainer'
import AboutUs from '../components/homepage/AboutUs'
import Specialization from '../components/homepage/Specialization'
import ProductsComp from '../components/homepage/ProductsComp'
import WhyChoose from '../components/homepage/WhyChoose'
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'

const Home = () => {
  return (
    <>
      {/* <BaseContainer> */}
      <div className='w-full max-w-[1440px] overflow-hidden m-auto'>

        <Navbar />
      </div>
      <div className='!max-w-[1920px] !w-full overflow-hidden m-auto'>

        <HeroSection />
      </div>
      <div>

      </div>


      <div className='bg-Primary_10 max-w-[1920px] m-auto'>
        <div className='w-full max-w-[1440px] overflow-hidden m-auto'>
          {/* ABOUT US */}
          <div className='py-compSpace max_md:py-compSpaceTab '>
            <InnerContainer>
              <AboutUs />
            </InnerContainer>
          </div>
        </div>
      </div>
      <div className='w-full max-w-[1440px] overflow-hidden m-auto'>
        {/* Specialization */}
        <div className='py-compSpace max_md:py-compSpaceTab bg-white'>
          <InnerContainer>
            <Specialization />
          </InnerContainer>
        </div>
      </div>

      <div className='bg-Primary_10 max-w-[1920px] m-auto'>
        <div className='w-full max-w-[1440px] overflow-hidden m-auto'>
          {/* Products  */}
          <div className='py-compSpace max_md:py-compSpaceTab '>
            <InnerContainer>
              <ProductsComp />
            </InnerContainer>
          </div>
        </div>
      </div>

      <div className='w-full max-w-[1440px] overflow-hidden m-auto'>
        {/* Why choos us */}
        <div className='py-compSpace max_md:py-compSpaceTab bg-white'>
          <InnerContainer>
            <WhyChoose />
          </InnerContainer>
        </div>
      </div>


      <div className='!max-w-[1920px] !w-full overflow-hidden m-auto'>

        <Footer />
      </div>

      {/* </BaseContainer> */}
    </>
  )
}

export default Home