import React from 'react'
import WhyUseCard from './WhyUseCard'
import WhyUS from '../../icons/whyUs/Why-us.png'
import WhyUS1 from '../../icons/whyUs/Why-us1.png'
import { DeliveryOnTime, GreatTech, HighQuality, ProfTeam } from '../../icons/whyUs/WhyUsIcons'

const WhyChoose = () => {
  return (
    <div className='flex flex-col items-center'>
      <p className='Title'>Why choose us</p>
      <p className='SubTitle font-medium mt-[20px] text-Light_Black text-center'>“Choose us for superior engine oils prioritizing performance, longevity, and efficiency. We invest in cutting-edge tech for excellence, ensuring your engine's health and your vehicle's reliability.”</p>



      <div className='hidden max_xl:block mt-[40px]'>
          {/* <img src={WhyUS} alt='whyus' /> */}
          <div class="circle1">
            <div className='absolute top-[20%] left-[-9%] w-[120%]'><img src={WhyUS1} className="w-full" alt='whyus' /></div>
          </div>
        </div>


      <div className='flex my-[40px] gap-x-[6px]  max_md:gap-y-[20px] max_xl:w-[70%] max_md:flex-col max_md:items-center justify-between w-full'>
        <div className='flex flex-col gap-y-[50px] max_md:gap-[20px]'>
          <WhyUseCard icon={<GreatTech />} title="GreatTech" />
          <WhyUseCard icon={<DeliveryOnTime />} title="Delivery On time" />
        </div>
        <div className='block max_xl:hidden'>
          {/* <img src={WhyUS} alt='whyus' /> */}
          <div class="circle">
            <div className='absolute top-[16%] left-[-20%] w-[140%]'><img src={WhyUS1} className="w-full" alt='whyus' /></div>
          </div>
        </div>
        <div className='flex flex-col gap-y-[50px] max_md:gap-[20px]'>
          <WhyUseCard icon={<ProfTeam />} title="Professional Team" />
          <WhyUseCard icon={<HighQuality />} title="High Quality Products" />
        </div>
      </div>


      {/* <div className='flex justify-between flex-wrap gap-x-[20px] w-full mt-[30px]'>
        {Specities.map((itm, idx) => (
          <Card key={idx} icon={itm.icon} title={itm.title} desc={itm.desc} />
        ))}
      </div> */}
    </div>
  )
}

export default WhyChoose