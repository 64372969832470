const WhyUseCard = ({ icon, title }) => {
  return (
    <div className='WhyUsCard transition-all duration-200 relative w-[245px] h-[101px] border border-Light_White rounded-[5px] flex justify-center mt-[50px]'>
      <div style={{ transform: 'translate(-50%)' }} className='icon absolute left-1/2 -top-[40%] bg-white w-[80px] h-[80px] rounded-full flex justify-center items-center border border-Light_White'>
        {/* <GreatTech /> */}
        {icon}
      </div>
      <div style={{ transform: 'translate(-50%)' }} className='absolute left-1/2 bottom-[20px]'>
        <p className='font-Roborto text-NavText max_md:text-NavTextTab text-Light_Black font-medium text-nowrap'>{title}</p>
      </div>
    </div>
  )
}

export default WhyUseCard