import React from 'react'
import BaseContainer from '../layout/BaseContainer'
import Footer from '../layout/Footer'

const Gallery = () => {
  return (
    <>

      <BaseContainer>
        <div className='min-h-[500px] flex justify-center items-center w-full'>
          <p className='Title'>Coming Soon</p>
        </div>
      </BaseContainer>
      <div className='!max-w-[1920px] !w-full overflow-hidden m-auto'>

        <Footer />
      </div>
    </>
  )
}

export default Gallery